






















import Vue from 'vue';
import adminnav from './adminnav.vue';

export default Vue.extend({
  name: 'sls-admin',
  components: { adminnav },
  data() {
    return {
      password: '',
      answer: 'slsjd2',
    };
  },
  computed: {
    passwordCorrect(): boolean {
      if (this.password === this.answer) {
        return true;
      }
      return false;
    },
  },
});
