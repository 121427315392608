











import Vue from 'vue';

const routes = [
  ['home', 'sls-admin-home'],
  ['data', 'sls-admin-data'],
  ['no offence but popup', 'sls-admin-nob-popup'],
  ['question popup', 'sls-admin-question-popup'],
  ['site alert', 'sls-admin-site-alert'],

];

export default Vue.extend({
  data() {
    return {
      routes,
    };
  }
  // mounted() {
  //   console.log(this.$router.options.routes);
  // },
});
